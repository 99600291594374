import React, { useState, useEffect } from "react";
import Input from "./inputs";
import thumbnail from "../assets/icon/artwork.svg";
import bin from "../assets/icon/bin.svg";
import { toast } from "react-toastify";

const ImageUpload = ({
  onChange,
  value,
  setImage,
  type,
  locate,
  setFileType,
}) => {
  const [selectedMedia, setSelectedMedia] = useState();
  const [limtExceeded, setLimitExceeded] = useState(false);
  const [size, setSize] = useState(0);

  // check the type of image
  // check the size of image
  // set to selected image

  useEffect(() => {
    if (value !== null) {
      if (locate === "Collection") {
        if (!value.type.startsWith("image/")) {
          toast.error("Only Images are allowed!");
          return;
        }
      }

      if (type === "video") {
        const maxSizeMB = 8;
        const maxSizeBytes = maxSizeMB * 1024 * 1024; // Convert MB to bytes
        setSize(maxSizeBytes);
        setLimitExceeded(value?.size > maxSizeBytes);
        if (value?.size > maxSizeBytes) {
          setSelectedMedia(null);
          setImage(null);
          setFileType(null);
          toast.error(`Video size exceeded (Max: ${maxSizeMB}MB)`);
          return;
        } else {
          setSelectedMedia(URL.createObjectURL(value));
        }
      } else {
        const maxSizeMB = 8;
        const maxSizeBytes = maxSizeMB * 1024 * 1024; // Convert MB to bytes
        setSize(maxSizeBytes);
        setLimitExceeded(value?.size > maxSizeBytes);
        if (value?.size > maxSizeBytes) {
          setSelectedMedia(null);

          setImage(null);

          toast.error(`Image size exceeded (Max: ${maxSizeMB}MB)`);
          return;
        } else {
          setSelectedMedia(value);
        }
      }
    }
  }, [value, type]);

  // handle image change
  // check type and size

  const handleMediaChange = (event) => {
    const media = event.target.files[0];

    if (media.type.startsWith("image/")) {
      setFileType("image");
    }

    if (locate === "Collection") {
      if (!media.type.startsWith("image/")) {
        toast("Only Images are allowed!");
        return;
      }
    }
    if (media) {
      if (media.type.startsWith("video/")) {
        const maxSizeMB = 30;
        const maxSizeBytes = maxSizeMB * 1024 * 1024; // Convert MB to bytes
        const limitExceeded = media.size > maxSizeBytes;
        setLimitExceeded(limitExceeded);

        if (limitExceeded) {
          setSelectedMedia(null);
          setImage(null);
          setFileType(null);

          toast.error(`Video size exceeded (Max: ${maxSizeMB}MB)`);
          return;
        } else {
          setImage(media);
          setFileType("video");
          setSelectedMedia(URL.createObjectURL(media));
        }
        return;
      } else {
        setFileType("image");
      }

      const reader = new FileReader();
      reader.onload = () => {
        setSelectedMedia(reader.result);
      };
      setImage(media);
      reader.readAsDataURL(media);
      onChange(event);
    } else {
      setSelectedMedia(null);
    }
  };


 
  const handleDeleteMedia = () => {
    if (selectedMedia) {
      setSelectedMedia(null);
      setImage(null); 
      setFileType(null);
      
      // Clear the input file field
      document.querySelector("input[type='file']").value = null;
    }
  };
  

  // if image is larger than limit delete it

  useEffect(() => {
    if (limtExceeded) {
      handleDeleteMedia();
    }
  }, [limtExceeded, value]);

  return (
    <div>
      <div className="file-thumbnails">
        <div className="mt-3 position-relative">
          <label className="file-thumbnail pointer position-relative">
            <img
              src={thumbnail}
              alt="thumbnail"
              className={`img-48 bin ${selectedMedia && !limtExceeded ? "d-none" : ""
                }`}
            />
            <Input
              type="file"
              className="d-none"
              // accept={type === "video" ? "video/*" : "image/*"}
              onChange={(e) => handleMediaChange(e)}
            />
            {!limtExceeded && selectedMedia && (
              <>
                {type === "video" ? (
                  <video
                    className="w-100 h-100 object-fit-cover"
                    loop
                    playsInline
                    autoPlay
                    muted
                    style={{ objectFit: "cover" }}
                  >
                    <source src={selectedMedia} type="video/mp4" />
                  </video>
                ) : (
                  <img
                    src={selectedMedia}
                    // alt="uploaded image"
                    className="img-100 opacity-100 uploaded-img"
                  />
                )}
              </>
            )}
          </label>
        </div>
        {!limtExceeded && (
          <img
            src={bin}
            alt="bin"
            className={`img-142 bin bg-white rounded-circle ${selectedMedia ? "" : "d-none"
              }`}
            onClick={handleDeleteMedia}
          />
        )}
      </div>
      {/* {selectedMedia && !limtExceeded && (
        <label className="medium text-black no-text-transform v-center h-center pointer change-btn bg-white mt-2 br-20 py-2">
          Change file
        </label>
      )} */}
    </div>
  );
};

export default ImageUpload;
