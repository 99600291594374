import React, { useEffect, useState } from "react";
import "../../style/main.scss";
import verified from "../../assets/icon/verified-artist-small.svg";
import sgb from "../../assets/icon/SGB.svg";
import FLR from "../../assets/icon//FLR.svg";
import profile from "../../assets/icon/profile-picture.svg";
import loader from "../../assets/icon/loader-medium.svg";
import chainImage from "../../assets/icon/SGB.svg";
import userProfile from "../../assets/images/face-3.png";
import vidIcon from "../../assets/icon/video-fill.svg";
import thumb from "../../assets/icon/profile-picture.svg";
import ArtistPopUp from "../shared/artistpopup";
import AsideFilterExplore from "../explore/asideFilterExplore";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import marketplaceContractABI from "../../abis/Marketplace/v3/abi.json";
import mintContractABI from "../../abis/SafeMint/v2/abi.json";
import Web3 from "web3";
import CardSkelton from "../shared/cardSkelton";
import config from "../../config";
import {
  getAllUsers,
  getArtLastPriceFromFirebase,
} from "../../firebase/firebase";
import { useAccount } from "wagmi";
import { Popover } from "antd";
import { list } from "firebase/storage";

const LayoutGrid = (props) => {
  // market place contract address
  const { marketplace, erc20 } = config;
  console.log(marketplace);
  console.log(erc20);



  const Marketplace_coston_contractAddress =
    marketplace.coston;
  const Marketplace_coston2_contractAddress =
    marketplace.coston2;
  const contractABI = marketplaceContractABI;

  const coston_Token = erc20.coston;
  const coston2_Token = erc20.coston2;

  const [collectionDetails, setCollectionDetails] = useState(props?.ApvNFT);
  const [nftDetails, setNftDetails] = useState([]);
  const [listedNfts, setListedNfts] = useState([]);
  const [web3, setWeb3] = useState(null);
  const { address } = useAccount();
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();
  const [collectionData, setCollectionData] = useState([]);
  const [nftFilteredDetails, setNftFilteredDetails] = useState(collectionData);

  const handleImageLoad = () => {
    setLoaded(true);
  };

  // setting rpc url

  useEffect(() => {
    if (collectionDetails?.selectedNetwork === "Coston") {
      setWeb3(new Web3(process.env.REACT_APP_COSTON_RPC_URL));
    }
    if (collectionDetails?.selectedNetwork === "Coston2") {
      setWeb3(new Web3(process.env.REACT_APP_COSTON2_RPC_URL));
    }
  }, [collectionDetails]);

  // get all listed data from blockchain

  const getAllListNFTData = async () => {
    if (web3 !== null) {
      let MarketplaceAddress;
      if (collectionDetails?.selectedNetwork === "Coston") {
        MarketplaceAddress = Marketplace_coston_contractAddress;
      } else if (collectionDetails?.selectedNetwork === "Coston2") {
        MarketplaceAddress = Marketplace_coston2_contractAddress;
      }

      const contract = new web3.eth.Contract(contractABI, MarketplaceAddress);
      const data = await contract.methods.getAllListedNfts().call();
      console.log(data, "listed nfts data");

      setListedNfts(data);
    }
  };

  useEffect(() => {
    setNftDetails(props?.nftDetails);
    getAllListNFTData();
  }, [props?.nftDetails, web3]);

  // get connected users nfts from blockchain

  const getUserNftsFromContract = async (uri) => {
    let web3_1;
    if (collectionDetails?.selectedNetwork === "Coston") {
      web3_1 = new Web3(process.env.REACT_APP_COSTON_RPC_URL);
    } else if (collectionDetails?.selectedNetwork === "Coston2") {
      web3_1 = new Web3(process.env.REACT_APP_COSTON2_RPC_URL);
    }

    try {
      if (web3_1 !== null) {
        const contract = new web3_1.eth.Contract(
          mintContractABI,
          collectionDetails?.contractAddress
        );
        console.log(contract, "contract --------");
        const contractOwner = await contract.methods.owner().call();


        return new Promise((resolve, reject) => {
          contract.methods
            .getTokenId(collectionDetails?.address)
            .call({ from: contractOwner }, async (error, result) => {
              if (error) {
                console.error(error);
                reject(error);
              } else {
                if (result?.length > 0) {
                  for (let a of result) {
                    if (a.uri === uri) {
                      resolve(a.tokenId);
                    }
                  }
                } else {
                  console.log("error");
                  reject("Error: No result found");
                }
              }
            });
        });
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };



  // mapping listed data to nfts


  useEffect(() => {
    if (props?.nfts !== undefined) {

      let nftListDetails = [];
      console.log(listedNfts, "listed nfts");

      props?.nfts.forEach((item) => {
        let isSaleListed = false;
        let isOfferListed = false;
        let saleCountIndex = 0;  // Initialize saleCountIndex
        let offerCountIndex = 0; // Initialize offerCountIndex


        // Check for sale listings
        listedNfts[0]?.forEach((nft) => {
          if (nft?.uriData === item?.uri) {
            isSaleListed = true;
            console.log(nft?.listCount);
            saleCountIndex = nft?.listCount; // Get listCount from sale listing
            nftListDetails.push({
              ...nft,
              ...item,
              isListed: true,
              isSaleListed: true,
              saleCountIndex, // Add saleCountIndex to the object
            });
          }
        });

        // Check for offer listings
        listedNfts[1]?.forEach((nft) => {
          if (nft?.uriData === item?.uri) {
            isOfferListed = true;
            offerCountIndex = nft?.listCount; // Get listCount from offer listing
            const existingIndex = nftListDetails.findIndex(
              (nftDetail) => nftDetail.uri === item.uri
            );

            if (existingIndex !== -1) {
              nftListDetails[existingIndex] = {
                ...nftListDetails[existingIndex],
                isOfferListed: true,
                offerCountIndex, // Add offerCountIndex to the object
              };
            } else {
              nftListDetails.push({
                ...nft,
                ...item,
                isListed: true,
                isOfferListed: true,
                offerCountIndex, // Add offerCountIndex to the object
              });
            }
          }
        });

        // Ensure both flags are set correctly for NFTs listed in both categories
        if (isSaleListed || isOfferListed) {
          const existingIndex = nftListDetails.findIndex(
            (nftDetail) => nftDetail.uri === item.uri
          );
          if (existingIndex !== -1) {
            nftListDetails[existingIndex] = {
              ...nftListDetails[existingIndex],
              isListed: true,
              isSaleListed: isSaleListed,
              isOfferListed: isOfferListed,
            };
          }
        }
      });

      console.log(nftListDetails, "nftdetails");

      // Fetch and set last price for each NFT
      nftListDetails.forEach(async (e) => {
        console.log(e?.uri, "artid");
        let Price = await getArtLastPriceFromFirebase(e?.uri);
        if (Price) {
          e.data.lastPrice = Price;
        }
      });

      bid(nftListDetails);

      setCollectionData(nftListDetails);
    } else {
      setCollectionData([]);
      setNftFilteredDetails([]);
    }
  }, [nftDetails, listedNfts, props?.nfts]);

  // calculating maximum offer price


  const bid = async (nfts) => {
    let data = nfts;
    let offerListed = nfts.filter((item) => item.isOfferListed);
    let MarketplaceAddress;

    console.log(data, "data in bidding...............");

    try {
      if (collectionDetails?.selectedNetwork === "Coston") {
        MarketplaceAddress = Marketplace_coston_contractAddress;
      } else if (collectionDetails?.selectedNetwork === "Coston2") {
        MarketplaceAddress = Marketplace_coston2_contractAddress;
      }

      // getting bidding history from blockchain
      const contract = new web3.eth.Contract(contractABI, MarketplaceAddress);

      const updatedDetails = await Promise.all(
        offerListed.map(async (item) => {
          try {
            const biddingHistoryCoston = await contract.methods
              .getBiddingHistory(item?.offerCountIndex)
              .call();

            // Calculate max price from bidding history
            const maxPrice = calculateMaxPrice(biddingHistoryCoston);

            return { ...item, maxPrice: maxPrice / 1000000000000000000 }; // Assuming maxPrice is in wei, converting to ether
          } catch (innerError) {
            console.error(`Error getting bidding history for item ${item.offerCountIndex}:`, innerError);
            return null; // Returning null if an error occurs for an individual item
          }
        })
      );

      setNftFilteredDetails((prevDetails) => {
        const unchangedItems = data.filter((prevItem) => !prevItem.isOfferListed);
        const mergedDetails = [
          ...unchangedItems,
          ...updatedDetails.filter(Boolean),
        ];
        return mergedDetails;
      });

    } catch (error) {
      console.error("Error in bid function:", error);
    }
  };

  // Function to calculate the maximum price from bidding history
  const calculateMaxPrice = (biddingHistoryArray) => {
    let maxPrice = 0;
    for (const biddingHistory of biddingHistoryArray) {
      for (const key in biddingHistory) {
        if (Object.prototype.hasOwnProperty.call(biddingHistory, key)) {
          const currentPrice = parseFloat(biddingHistory[key]);
          if (currentPrice && !isNaN(currentPrice) && currentPrice > maxPrice) {
            maxPrice = currentPrice;
          }
        }
      }
    }
    return maxPrice;
  };

  // Filters (Recently Minted, Recently Listed, Lowest Price, Highest Price)
  useEffect(() => {
    if (props?.sortFilter !== undefined) {
      let sortedData = [];
      if (props?.sortFilter === "Recently Minted") {
        sortedData = collectionData.sort((a, b) => {
          const dateA = new Date(a?.data?.mintedAt);
          const dateB = new Date(b?.data?.mintedAt);
          return dateB - dateA;
        });
      } else if (props?.sortFilter === "Recently Listed") {
        sortedData = collectionData
          .filter((item) => {
            return item.isListed;
          })
          .sort((a, b) => {
            const dateA = new Date(parseInt(a?.listedData?.listTime) * 1000);
            const dateB = new Date(parseInt(b?.listedData?.listTime) * 1000);
            return dateB - dateA;
          });
      } else if (props?.sortFilter === "Lowest price") {
        if (collectionData?.length > 0) {
          sortedData = collectionData
            .filter((item) => {
              return item.isListed;
            })
            .sort((a, b) => {
              if (a.isSaleListed && b.isSaleListed) {
                return (
                  parseFloat(a?.listedData?.price) -
                  parseFloat(b?.listedData?.price)
                );
              }
              if (a.isOfferListed && b.isOfferListed) {
                return (
                  parseFloat(a?.listedData?.minimumBid) -
                  parseFloat(b?.listedData?.minimumBid)
                );
              }
            });
        }
      } else if (props?.sortFilter === "Highest price") {
        if (collectionData.length > 0) {
          sortedData = collectionData
            .filter((item) => {
              return item.isListed; // Only keep listed items
            })
            .sort((a, b) => {
              // Prioritize sale listings over offer listings
              if (a.isSaleListed && b.isSaleListed) {
                return (
                  parseFloat(b?.listedData?.price) -
                  parseFloat(a?.listedData?.price)
                );
              }
              if (a.isOfferListed && b.isOfferListed) {
                return (
                  parseFloat(b?.listedData?.minimumBid) -
                  parseFloat(a?.listedData?.minimumBid)
                );
              }
            });
        }
      }
      setNftFilteredDetails(sortedData);
    }
  }, [props?.sortFilter]);

  const handleSelectedFilterChange = (newFilter) => {
    console.log(newFilter, "new filter");

    let filteredData = [];

    if (collectionData?.length > 0) {
      console.log(collectionData, "collection data in layout grid");

      // Define conversion rates for USD based on selected blockchain
      const conversionRates = {
        coston: props.sgb,
        coston2: props.flr,
      };

      // Function to apply price filter
      const applyPriceFilter = (item) => {
        let priceFilter = true;

        if (newFilter.selectPrice.min !== "" || newFilter.selectPrice.max !== "") {
          let priceInEth = parseFloat(item?.listedData?.price) / 1e18;

          console.log(item?.data?.selectedBlockchain?.toLowerCase(), "CURRENCY VALUE");

          // Adjust price based on selected priceCurrency
          if (newFilter.priceCurrency === "USD") {
            const blockchain = item?.metadata?.selectedBlockchain?.toLowerCase();
            const conversionRate = conversionRates[blockchain] || 1; // Default rate if not found
            priceInEth *= conversionRate;
            console.log(priceInEth, "price in USD");
          } else if (
            newFilter.priceCurrency === "CFLR" &&
            item?.data?.selectedBlockchain?.toLowerCase() !== "coston"
          ) {
            priceFilter = false;
          } else if (
            newFilter.priceCurrency === "C2FLR" &&
            item?.data?.selectedBlockchain?.toLowerCase() !== "coston2"
          ) {
            priceFilter = false;
          } else if (
            newFilter.priceCurrency === "SGB" &&
            item.data.selectedBlockchain?.toLowerCase() !== "sgb"
          ) {
            priceFilter = false;
          } else if (
            newFilter.priceCurrency === "FLR" &&
            item.data.selectedBlockchain?.toLowerCase() !== "flr"
          ) {
            priceFilter = false;
          }

          // Apply price range filtering
          if (priceFilter) {
            priceFilter =
              (newFilter.selectPrice.min === "" || priceInEth >= parseFloat(newFilter.selectPrice.min)) &&
              (newFilter.selectPrice.max === "" || priceInEth <= parseFloat(newFilter.selectPrice.max));
          }
        }

        return priceFilter;
      };

      // Get current time for early access calculation
      const now = Date.now();

      collectionData.forEach((item) => {
        console.log(item.isSaleListed, 'item data in collection');
        const isCategorySelected = Object.values(newFilter.selectCategories).some((category) => category);
        const selectedCurrency = Object.values(newFilter.selectCurrency).some((currency) => currency);

        let categoriesFilter = true;
        let currencyFilter = true;
        let statusFilter = true;
        let earlyAccessFilter = true;

        // Calculate early access based on listTime (Unix timestamp in seconds)
        const listTime = item?.listedData.listTime;

        console.log(listTime,"list time of data");
        const mintedAt = listTime ? new Date(listTime * 1000) : null;

        if (mintedAt ) {
          const timeElapsed = now - mintedAt; // Calculate the time difference in milliseconds
          const hoursElapsed = Math.floor(timeElapsed / (1000 * 60 * 60)); // Convert milliseconds to hours
          earlyAccessFilter = hoursElapsed < 24 && item.isSaleListed; // Check if minted within the last 24 hours
        }

        console.log(earlyAccessFilter, 'early access filter status');

        // Apply categories filter
        if (isCategorySelected) {
          categoriesFilter = Object.keys(newFilter.selectCategories).every(
            (category) =>
              newFilter.selectCategories[category]
                ? item?.data?.selectedTags?.includes(category)
                : true
          );
        }

        // Apply currency filter
        if (selectedCurrency) {
          currencyFilter =
            newFilter.selectCurrency.allChains ||
            (newFilter.selectCurrency.flr && item?.data?.selectedBlockchain?.toLowerCase() === "flare network") ||
            (newFilter.selectCurrency.sgb && item?.data?.selectedBlockchain?.toLowerCase() === "songbird network") ||
            (newFilter.selectCurrency.coston && item?.data?.selectedBlockchain?.toLowerCase() === "coston") ||
            (newFilter.selectCurrency.coston2 && item?.data?.selectedBlockchain?.toLowerCase() === "coston2");
        }

        // Apply status filter
        if (
          newFilter.selectStatus.buyNow ||
          newFilter.selectStatus.onOffer ||
          newFilter.selectStatus.new ||
          newFilter.selectStatus.earlyAccess // Check for early access filter
        ) {
          statusFilter =
            (newFilter.selectStatus.buyNow && item?.isSaleListed) ||
            (newFilter.selectStatus.onOffer && item?.isOfferListed) ||
            (newFilter.selectStatus.new && !item?.isListed) ||
            (newFilter.selectStatus.earlyAccess && earlyAccessFilter);
        }

        // Apply all filters
        if (categoriesFilter && currencyFilter && applyPriceFilter(item) && statusFilter) {
          filteredData.push(item);
        }
      });

      console.log(filteredData, "filtered data");

      // Set the filtered data
      setNftFilteredDetails(filteredData);
    }
  };



  const navigateToSingleArtwork = async (item) => {
    let collectionId = item?.data.selectedCollectionId;
    let network = item?.data.selectedBlockchain;
    let tokenId;
    if (item?.listedData) {
      tokenId = item?.listedData.tokenId;
    } else {
      tokenId = await item?.tokenId;
    }
    navigate(`/artwork/${network}/${collectionId}/${tokenId}/`);
  };

  const [userData, setUserData] = useState([]);

  const checkUser = async () => {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setUserData(user);
    }
  };

  useEffect(() => {
    checkUser();
    const fetchUserData = async () => {
      const users = await getAllUsers();
      setUserData(users);
    };
    fetchUserData();
  }, []);

  // get artist name by address

  const getArtistNamebyAdress = (accountAddress) => {
    let artistName = "";

    if (address === accountAddress) {
      return "You";
    } else {
      userData?.forEach((user) => {
        if (user?.id === accountAddress) {
          artistName = user?.userName;
        }
      });
    }

    return "@" + artistName;
  };

  const tooltip1 = (artPrice) => {
    return <Tooltip id="tooltip1">{artPrice}</Tooltip>;
  };

  const tooltip2 = (chainName) => {
    return <Tooltip id="tooltip2">Chain: {chainName}</Tooltip>;
  };

  // get artist image by address

  const getArtistImage = (accountAddress) => {
    let artistImage = userProfile;
    userData?.forEach((user) => {
      if (user?.id === accountAddress) {
        if (user?.image) {
          artistImage = user?.image;
        } else {
          artistImage = thumb;
        }
      }
    });

    return artistImage;
  };

  // Filters (highest price, lowest , price, recently listed)
  useEffect(() => {
    let activeCollection = props?.activeCollection;

    if (nftFilteredDetails) {
      // Sorting by highest price
      if (activeCollection === "Highest price") {
        const sortByHighestPrice = [...nftFilteredDetails].sort((a, b) => {
          return b?.data && b?.data.listedData
            ? b?.data.listedData.price -
            (a?.data && a.data.listedData ? a?.data.listedData.price : 0)
            : 0;
        });
        setNftFilteredDetails(sortByHighestPrice);
      }
      // Sorting by lowest price
      else if (activeCollection === "Lowest price") {
        const sortByLowestPrice = [...nftFilteredDetails].sort((a, b) => {
          return (
            (a?.data && a?.data.listedData ? a?.data.listedData.price : 0) -
            (b?.data && b?.data.listedData ? b?.data.listedData.price : 0)
          );
        });
        setNftFilteredDetails(sortByLowestPrice);
      }
      // Getting the first 5 recently listed items
      else if (activeCollection === "Recently listed") {
        const recentlyAdded = [...nftFilteredDetails]
          .sort((a, b) => {
            return b?.data && b?.data?.listedData
              ? b?.data?.listedData.listTime -
              (a?.data && a?.data.listedData
                ? a?.data.listedData.listTime
                : 0)
              : 0;
          })
          .slice(0, 5);
        setNftFilteredDetails(recentlyAdded);
      }
    }
  }, [nftFilteredDetails, props, props?.activeCollection]);

  // set number of total nfts

  useEffect(() => {


    console.log(nftFilteredDetails, "nft filtered details");
    props.setItemNumber(nftFilteredDetails.length);

    // Filter items with `newOwner` and extract unique `newOwner` values
    let newOwners = nftFilteredDetails
      .filter(item => item.newOwner)  // Filter items that have `newOwner`
      .map(item => item.newOwner);    // Map to `newOwner` values

    let uniqueNewOwners = [...new Set(newOwners)];  // Extract unique `newOwner` values
    let newOwnerCount = uniqueNewOwners.length;
    console.log(newOwnerCount, "new owner count");

    // Set the count of unique `newOwner`s
    props?.setOwner(newOwnerCount);

    // Filter the listed NFTs
    let listed = nftFilteredDetails.filter((item) => item.isSaleListed === true);
    console.log(listed, "listed");

    // Calculate the percentage of listed NFTs
    let percentageListed = (listed.length / nftFilteredDetails.length) * 100;
    props?.setListedPercent(percentageListed.toFixed(2));


    let floorPrice = listed.length > 0 ? Math.min(...listed.map(item => item?.listedData?.price)) : "--";
    props?.setFloorPrice(floorPrice / 1000000000000000000);

    console.log(`Floor price of listed NFTs: ${floorPrice}`);

    console.log(`Percentage of NFTs listed: ${percentageListed.toFixed(2)}%`);


  }, [nftFilteredDetails]);


  useEffect(() => {

    console.log(nftFilteredDetails, "props in collection layout grid");
  }, [props])

  return (
    <div>
      <div
        className={
          props.flag ? "d-flex align-item-center justify-content-between" : ""
        }
      >
        <div className={props.flag ? "my-filter w-25" : "d-none"}>
          {/* filters */}
          <AsideFilterExplore
            onSelectedFilterChange={handleSelectedFilterChange}
            clearAll={props?.clearAll}
          />
        </div>
        {/* lazy loading */}
        {props.loading ? (
          <div
            className={`grid-display artworkGrid ? "w-75 active" : ""
              `}
          >
            <CardSkelton />
            <CardSkelton />
            <CardSkelton />
            <CardSkelton />
          </div>
        ) : (
          <div
            className={`grid-display artworkGrid  ${props.flag ? "w-75 active" : ""
              }`}
          >
            {/* nfts */}
            {props?.nfts == undefined ? (
              <></>
            ) : (
              nftFilteredDetails.map((item, index) => {
                console.log(item, "item");
                return (
                  <div className="collection-grid-card" key={index}>
                    <div className="card-head ">
                      <div onClick={() => {
                        navigateToSingleArtwork(item);
                      }} className="user-img">
                        {item && (
                          <img
                            src={
                              item?.newOwner
                                ? getArtistImage(item?.data?.artistAddress)
                                : thumb
                            }
                            className="img-100 rounded-circle"
                          />
                        )}
                        {!item && (
                          <img src={thumb} className="img-100 rounded-circle" />
                        )}
                      </div>

                      <div className="user-name">
                        <p
                          onClick={() => {
                            navigateToSingleArtwork(item);
                          }}
                          className="body-large hover-underline pointer"
                        >
                          {item?.data?.artName.length > 20 ? (
                            <>
                              {item?.data?.artName.substring(0, 20) ||
                                "Art Name"}
                              ...
                            </>
                          ) : (
                            <>{item?.data?.artName || "Art Name"}</>
                          )}
                        </p>
                        <Popover
                          placement="bottom"
                          trigger="hover"
                          content={
                            <>
                              {address && (
                                <ArtistPopUp
                                  userProfile={getArtistImage(
                                    item?.newOwner
                                  )}
                                  verified={verified}
                                  artistName={getArtistNamebyAdress(
                                    item?.newOwner
                                  )} // passing artist as prop
                                />
                              )}
                            </>
                          }
                        >
                          <p
                            onClick={() => {
                              navigate(`/profile/${item?.data?.artistAddress}`);
                            }}
                            className="body-medium fw-bold v-center text-black show-artist-popups hover-underline pointer"
                          >

                            {getArtistNamebyAdress(item?.data?.artistAddress)
                              .length > 18
                              ? getArtistNamebyAdress(
                                item?.newOwner
                              ).substring(0, 18) + "..."
                              : getArtistNamebyAdress(
                                item?.newOwner
                              )}
                            <img
                              src={verified}
                              alt="verified"
                              className="img-18 ms-1"
                            />
                          </p>
                        </Popover>
                      </div>
                    </div>

                    <div
                      className="card-body"
                      onClick={() => {
                        navigateToSingleArtwork(item);
                      }}
                    >
                      <div className="art-img">
                        {item?.data?.image ? (
                          <img
                            src={item?.data?.image}
                            alt="art"
                            className={`img-100 artwork-hover ${loaded ? "loaded" : ""
                              }`}
                            onLoad={handleImageLoad}
                          />
                        ) : (
                          <img
                            src={item?.data?.previewImg}
                            alt="Preview"
                            className={`img-100 artwork-hover ${loaded ? "loaded" : ""
                              }`}
                            onLoad={handleImageLoad}
                          />
                        )}

                        <img
                          src={chainImage}
                          alt="chain"
                          className={`chainImage ${loaded ? "loaded" : ""}`}
                          onLoad={handleImageLoad}
                        />
                      </div>

                      {/* if songbird */}
                      {item.data.selectedBlockchain === "Coston" && item?.listedData?.price ? (
                        <>
                          <div className="chain-logo ">
                            <OverlayTrigger
                              placement="top"
                              overlay={tooltip2(item?.data?.selectedBlockchain)}
                              id="tooltip1"
                            >
                              <img src={sgb} alt="chain logo" />
                            </OverlayTrigger>
                          </div>
                          <OverlayTrigger
                            placement="top"
                            overlay={tooltip1(
                              (item.isSaleListed &&
                                item?.listedData?.price /
                                1000000000000000000)
                            )}
                            id="tooltip1"
                          >
                            <div className="sgb">
                              <img src={sgb} alt="sgb" />
                              <p className="body-large text-white ms-1">
                                {(item.isSaleListed &&
                                  item?.listedData?.price /
                                  1000000000000000000)}
                              </p>
                            </div>
                          </OverlayTrigger>
                        </>
                      ) : item.data.selectedBlockchain === "Coston2" && item?.listedData?.price ? (
                        <>
                          <div className="chain-logo ">
                            <OverlayTrigger
                              placement="top"
                              overlay={tooltip2(item?.data?.selectedBlockchain)}
                              id="tooltip1"
                            >
                              <img src={FLR} alt="chain logo" />
                            </OverlayTrigger>
                          </div>
                          <OverlayTrigger
                            placement="top"
                            overlay={tooltip1(
                              (item.isSaleListed &&
                                item?.listedData?.price /
                                1000000000000000000)
                            )}
                            id="tooltip1"
                          >
                            <div className="sgb">
                              <img src={FLR} alt="FLR" />
                              <p className="body-large text-white ms-1">
                                {(item.isSaleListed &&
                                  item?.listedData?.price /
                                  1000000000000000000)}
                              </p>
                            </div>
                          </OverlayTrigger>
                        </>
                      ) : (
                        <></>
                      )}
                      {item?.data?.videoUrl && (
                        <div className="video-icon  position-absolute   ">
                          <img src={vidIcon} alt="" />
                        </div>
                      )}
                    </div>

                    <div className="card-footer">
                      <div className="owner show-artist-popup">
                        <p className="body-medium text-medium-grey ">Owner</p>

                        <Popover
                          placement="top"
                          trigger="hover"
                          content={
                            <>
                              {address && (
                                <ArtistPopUp
                                  userProfile={getArtistImage(
                                    item?.newOwner
                                  )}
                                  verified={verified}
                                  artistName={getArtistNamebyAdress(
                                    item?.newOwner
                                  )}
                                />
                              )}
                            </>
                          }
                        >
                          <p
                            onClick={() => {
                              navigate(`/profile/${item?.data.artistAddress}`);
                            }}
                            className="body-medium fw-bold v-center text-black pointer"
                          >
                            <img src={profile} alt="profile" />

                            {getArtistNamebyAdress(item?.data?.artistAddress)
                              .length > 18
                              ? getArtistNamebyAdress(
                                item?.newOwner
                              ).substring(0, 18) + "..."
                              : getArtistNamebyAdress(
                                item?.newOwner
                              )}
                          </p>
                        </Popover>
                      </div>

                      <div className="offer">
                        {item?.data?.lastPrice ? (
                          <>
                            <p className="body-medium text-medium-grey">
                              Last Price
                            </p>
                            <label className="medium text-black">
                              {item?.data?.lastPrice / 1000000000000000000}
                            </label>
                          </>
                        ) : item?.isOfferListed ? (
                          <>
                            <p className="body-medium text-medium-grey">
                              Best Offer
                            </p>
                            <label className="medium text-black">
                              {item?.maxPrice}
                            </label>
                          </>
                        ) : (
                          <>
                            <p className="body-medium text-medium-grey">
                              Price
                            </p>
                            <label className="medium text-black">--</label>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        )}
      </div>
      <div className="content-loader rotate-360 d-none">
        <img src={loader} alt="loader" />
      </div>
    </div>
  );
};
export default LayoutGrid;
